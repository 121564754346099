body
{
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin: 0;
    padding: 0;
    background:#0c0d0d!important;
}
:root {
    --primary-color: #85d47f;
    --primary-dark-color: #75c270;
    --grey-color:#141414;
    --light-grey-color:#383838;
    --hover-grey-color:#38383860;
    --primary-error:#ff757c;
    --primary-white:#fff;
    --primary-light-white:#d6d6d6;
    --primary-highlight:#fff;
}
.italic
{
    font-style: italic;
}
.hidden
{
    display: none;
}
.visible
{
    display:initial;
}
.loading
{
    background: url('./img/loading.gif') no-repeat;
    background-size: contain;
    width:35px;
    height:35px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.error
{
    color:#f06b6b;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.wrapper
{
    width:70%;
    min-height:600px;   
    height:100%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    gap:20px;
    padding:0 0 50px 0
}
.navbar
{
    width:70%;
    height:80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#fff;
    margin:0 auto;
}
.topnavbar
{
    border-bottom: 1px solid var(--light-grey-color);
    margin:0 0 50px 0;
}
#brandlogo
{
    background:url('./img/BrandLogo.png') no-repeat;
    background-size: contain;
    width:230px;
    height:65px;
}
#cartbox
{
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
}
#cartbox .btn-cart
{
    display: flex;
    justify-content: center;
    align-items: center;
    width:90px;
    background-color: transparent;
    border:none;
}
#cartbox .btn-cart:hover
{
    background:var(--hover-grey-color);
    border-radius:50px;
}

#shopping-cart
{
    background:url('./img/shopping-cart.png') no-repeat;
    background-size: contain;
    width:30px;
    height:28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-badge
{
    background-color: var(--primary-color)!important;
    color:var(--grey-color);
    position:absolute!important;
    top:0;
    right:0;
}
.content
{
    width:100%;
}
.store
{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap:80px;
}
.containerbox
{
    width:280px;
    display: flex;
    flex-direction: column;
    gap:10px;
    padding:10px 0;
} 
.container-img
{
    width:100%;
    background:var(--grey-color);
    border-radius:20%;
    overflow: hidden;
    transform: scale(1);
    position:relative;
}
.container-img img
{
    width:280px;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.container-img .discount
{
    position:absolute;
    top:20px;
    left:20px;
}
.container-title .product-stock-low
{
    font-size:12px;
    top:60px;
    left:20px;
}
.container-img:hover img
{
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
}

.container-title
{
    color:var(--primary-color);
    font-size:18px;
}
.container-footer
{
    width:95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap:15px;
}
.container-footer-info
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:10px;
}
.container-price
{
    color:#fff;
    height:40px;
    border:1px solid var(--light-grey-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    border-radius: 90px;
    font-size:15px;
    cursor:default;
    padding:0 10px;
    transition:0.5s;
}
.container-price s
{
    font-size:13px;
}
.container-price:hover
{
    opacity: .6;
}
.container-add-to-cart
{
    color:var(--grey-color);
    width:100%;
    height:40px;
    background:var(--primary-color);
    border:1px solid var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    font-size:15px;
    outline-color: var(--light-grey-color);
    position:relative;
}

.container-add-to-cart[disabled]
{
    color:var(--primary-light-white);
    background:var(--light-grey-color);
    border:1px solid var(--grey-color);
    outline-color: var(--primary-light-white);
    opacity: .4;
    cursor:not-allowed;
    transition:none!important;
}

.container-add-to-cart span
{
    background:url('./img/shopping-bag-add.png') no-repeat;
    background-size:contain;
    width:27px;
    height:27px;
    position:absolute;
    left:0;
    transition:0.5s;
    opacity: 0;
}
.container-add-to-cart:hover span
{
    opacity: .8;
    margin:0 0 0 -35px;
}

.cartBox
{
    display: flex;
    flex-direction: row;
}

.cart_1
{
    display: flex;
    flex:1
}
.cartPriceTxt
{
    display: none;
}
.cartImage
{
    width:130px;
    height:130px;
    margin:10px;
}
.cartImage img
{
    width:100%;
    height:100%;
}
.cartTitle
{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:8px;

}
.cartTitle span
{
    font-size:30px;
}
.cartPrice
{
    font-size:16px;
    font-style: italic;
    opacity: .8;
}
.cartQuantity
{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:25px;
    gap:10px;
}

.cartBox button
{
    width:80px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    background: none;
}
#trash
{
    background:url('./img/trash.png') no-repeat;
    background-size: contain;
    width:18px;
    height: 20px;
    opacity: .5;
    transition: 0.5s;
}
#trash:hover
{
    opacity: .8;
}

.productTotal
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantitySelector
{
    border:1px solid #0000002c;
    height:45px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:2px;
}
.quantitySelector button
{
    display: flex;
    height:100%;
    width:50px;
}
.decreaseQuantity, .increaseQuantity
{
    color:var(--grey-color);
    opacity: .6;
}
.quantitySelector span
{
    font-size:20px;
    width:60px;
    text-align: center;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.finalPriceBox
{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap:15px;
}
.finalPriceBox div > span:first-child
{
    font-size:25px;
}
.finalPriceBox div > span:nth-child(2)
{
    font-size:25px;
    font-style: italic;
    
}
.emptyCart
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:30px;
    min-height:250px;
    text-align: center;
}
.emptyCart div
{
    font-size:30px;
}
.purchase-btn
{
    width:250px;
    height:55px;
    background-color: var(--primary-color);
    color: var(--grey-color);
    border:none;
    transition: 0.3s;
    font-size:20px;
}
.purchase-btn:hover
{
    background-color: var(--primary-dark-color);
    color: var(--grey-color);
}
.cartLegend
{
    display: flex;
    justify-content: space-around;
}
.cartLegend div
{
    flex:1;
    font-size:16px;
}
.cartLegend > div:nth-child(1)
{
    flex:2.5;
    text-align: left;
}
.cartLegend > div:nth-child(2)
{
    text-align: center;
}
.cartLegend > div:nth-child(3)
{
    text-align: center;
}
.placeOrderBox
{
    width:100%;
    display: flex;
    gap:30px;
}
.submitDetails
{
    flex:1;
    padding:0 20px 0 0;
}

.placeOrderBox .cartLegend
{
    color:var(--light-grey-color);
}
.placeOrderBox .cartTitle, .placeOrderBox .cartQuantity, .placeOrderBox .productTotal, .placeOrderBox.increaseQuantity, .placeOrderBox .decreaseQuantity 
{
    color:#fff;
    opacity: .8;
}
.placeOrderBox .productTotal
{
    display: flex;
    justify-content: flex-end;
    text-align: right;
}
.placeOrderBox .cartTitle >:nth-child(1)
{
    font-size:20px;
}
.placeOrderBox .cartTitle >:nth-child(2)
{
    font-size:15px;
}
.placeOrderBox .cartBox
{
    width:500px;
    height:100px;
    align-items: center;
    border-bottom:1px solid var(--light-grey-color);
    padding:0 10px;
}
.placeOrderBox .cartBox .cartImage
{
    width:90px;
    height:90px;
}
.placeOrderBox .emptyCart
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#fff;
    text-align: center;
    width:100%;
}
.placeOrderBox .finalPriceBox div
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    gap:5px;
    margin:10px 0 0 0;
}
.placeOrderBox .finalPriceBox div span
{
    font-size:20px;
    color:#fff;
    color:var(--primary-color);
}
.placeorder_column
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:15px;
    margin:0 0 15px 0;
}
.placeorder_column > div
{
    flex:1;
}
.placeorder_terms
{
    color:#fff;
}
.placeorder_terms a
{
    color:var(--primary-color);
    text-decoration: none;
}
.placeorder_terms a:hover
{
    text-decoration: underline;
}
.form-select
{
    font-size:16px;
}
.placeOrder_text
{
    color:var(--primary-color);
    font-size:20px;
    margin:0 0 15px 0;
}
.placeOrder_text span
{
    color:#fff;
    font-size:15px;
    opacity: .8;
}
.placeOrderBox hr
{
    color: #fff;
}
.cart-continue
{
    display: flex;
    justify-content: center;
    width:100%;
}
.cart-continue Button
{
    background-color: var(--primary-color);
    color:var(--grey-color);
    border-color: var(--primary-color);
}
.cart-continue Button:Hover
{
    background-color:var(--primary-dark-color);
    color:var(--grey-color);
    border-color: var(--primary-color);
}
.success
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:90%;
    text-align: center;
}
.success_show
{
    color:var(--primary-color);
    font-size:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
}
.success-icon
{
    background: url('./img/success.png') no-repeat;
    width:35px;
    height:35px;
    background-size: contain;
}
.succes_info
{
    color:#fff;
    font-size:18px;
    font-style: italic;
    opacity: .8;
}
.error
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:90%;
    text-align: center;
}
.error_show
{
    color:var(--primary-error);
    font-size:20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:20px;
}
.error_show :nth-child(1)
{
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: center;
    align-items: center;
}
.error-icon
{
    background: url('./img/error.png') no-repeat;
    width:25px;
    height:25px;
    background-size: contain;
}
.error_info
{
    color:#fff;
    font-size:18px;
    font-style: italic;
    opacity: .8;
}
.submit-order-error
{
    margin:15px 0;
    color:var(--primary-error);
}
.order-loading
{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: #000000d8;
    z-index: 999;
    height:100%;
}
.order-details-box
{
    width:500px;
}
.accepted-cards
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap:8px;
}
.card
{
    width:34px;
    height:26px;
    background-size: contain;
    border: none;
}
.visa
{
    background:url('./img/visa.svg') no-repeat;
}
.mastercard
{
    background:url('./img/mastercard.svg') no-repeat;
}
.americanexpress
{
    background:url('./img/amex.svg') no-repeat;
}
.unionpay
{
    background:url('./img/unionpay.svg') no-repeat;
}
.applepay
{
    background:url('./img/apple-pay.svg') no-repeat;
    width:34px;
    height:34px;
}
.googlepay
{
    background:url('./img/google-pay.svg') no-repeat;
    width:34px;
    height:34px;
}
.shipping
{
    width:34px;
    height:26px;
    background:url('./img/shipping.png') no-repeat;
    background-size: contain;
}
.money
{
    width:34px;
    height:26px;
    background:url('./img/shipping-money.png') no-repeat;
    background-size: contain;  
}
.box
{
    width:34px;
    height:25px;
    background:url('./img/shipping-box.png') no-repeat;
    background-size: contain;  
}
.footer
{
    width:100%;
    background: var(--grey-color);
    padding:10px 0;
    display: flex;
    justify-content: center;
}
.footer-wrapper
{
    width:70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
}
.footer-columns
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width:100%;
    padding:20px 0
}
.footer-columns div:nth-child(2)
{
    display:flex
}
.footer-l-box
{
    min-width:150px;
    display: flex;
    flex-direction: row;
    gap:15px;
}
.footer-l-box a
{
    font-size:14px;
    color:var(--primary-color);
    text-decoration: none;
}
.footer-l-box a:hover
{
    color:var(--primary-dark-color);
    text-decoration: underline;
}
.footer-contact
{
    width:250px;
    color:#ffffffa2;
    display: flex;
    flex-direction: column;
}
.footer-contact i
{
    opacity: .7;
}
.footer-cards
{
    justify-content: center;
}
.anpc-box
{
    display: flex;
    justify-content: center;
    gap:15px;
    margin:10px 0;
}
.anpc-sal
{
    background: url('./img/anpc-sal.png') no-repeat;
    background-size: contain;
    width:180px;
    height:46px;
}
.anpc-sol
{
    background: url('./img/anpc-sol.png') no-repeat;
    background-size: contain;
    width:180px;
    height:46px;
}
.footer-brand-box
{
    color:#ffffffa2;
}

.copyright
{
    color:#ffffff56;
}
#brand-footer
{
    background:url('./img/BrandLogo_footer.png') no-repeat;
    background-size: contain;
    width:230px;
    height:65px;
}
.socials-box
{
    display: flex;
    align-items: center;
    gap:10px;
    margin:0 0 10px 0;
}
.socials
{
    opacity: .3;
}

.facebook
{
    background:url('./img/social-facebook.svg') no-repeat;
    background-size: contain;
    width:32px;
    height:32px;
}
.instagram
{
    background:url('./img/social-instagram.svg') no-repeat;
    background-size: contain;
    width:31px;
    height:31px;
}
.tiktok
{
    background:url('./img/social-tiktok.svg') no-repeat;
    background-size: contain;
    width:30px;
    height:30px;
}
.content-box
{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color:var(--primary-light-white);
    font-size:18px;
}
.content-box h2
{
    font-size:25px;
    margin:15px 0;
}
.content-box span
{
    margin:10px 0;
}
.content-box i
{
    color: var(--primary-color);
}
.content-box a
{
    color:var(--primary-color);
    text-decoration: underline;
}
.content-box a:hover
{
    text-decoration: none;
}
.product-page
{
    display:flex;
    flex-direction: row;
}
.product-page-w
{
    padding:10px;
}
.product-page-w img
{
    width:100%;
    border-radius:5%;
}
.product-page-r
{
    flex:1;
    padding:0 10px;
}
.product-page-r h1
{
    font-size:25px;
    color:var(--primary-color);
}
.product-page-r button
{
    font-size:18px;
    width:100%;
}
.product-price
{
    font-size:25px;
    color:var(--primary-light-white);
    margin:15px 0 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    line-height: 90%;
}
.product-price s
{
    font-size:16px;
    opacity: .8;
    height: 100%;
    line-height: 90%;
}
.product-price span
{
    line-height: 90%;
}
.discount
{
    font-size:12px;
    background: var(--primary-color);
    color: var(--grey-color);
    padding:7px 10px;
    border-radius:35px;
}
.product-stock
{
    color: var(--primary-light-white);
    font-size:15px;
}
.product-stock-low
{
    color:var(--primary-error)
}
.product-description
{
    margin:15px 0;
}
.product-description span
{
    font-size:17px;
    color:var(--primary-light-white);
}
.product-description h2
{
    font-size:20px;
    color:var(--primary-white)
}
.product-description img
{
    width:100%;
    padding:10px 0;
    border-radius:20%;
}
.product-info
{
    margin:30px 0;
    display: flex;
    gap:30px;
}
.product-info-c
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color:var(--primary-light-white);
    font-size: 14px;;
}
.i-shipping
{
    background: url('./img/info-shipping.png') no-repeat;
    background-size: contain;
    width:35px;
    height:32px;

}
.i-return
{
    background: url('./img/info-retur.png') no-repeat;
    background-size: contain;
    width:31px;
    height:32px;
}
.i-freeshipping
{
    background: url('./img/info-freeshipping.png') no-repeat;
    background-size: contain;
    width:34px;
    height:32px;
}
@media (max-width:1600px)
{
    .product-page-w
    {
        width:600px;
    }
}
@media (max-width:1400px)
{
    .wrapper, .footer-wrapper
    {
        width:90%;
    }
}
@media (max-width:1000px)
{
    .product-page-w
    {
        width:100%;
    }
    .hr
    {
        display: none;
    }
    .cartLegend
    {
        display: none;
    }
    .cartBox
    {
        flex-direction: column;
        align-items: flex-start;
    }
    .productTotal
    {
        font-size:20px;
        margin:10px 0 0 0;
    }
    .cartPriceTxt
    {
        display: initial;
        margin:0 10px 0 0;
    }
    .placeOrderBox
    {
        flex-direction: column;
    }
    .order-details-box
    {
        width:100%;
        order:-1;
    }
    .placeOrderBox .cartBox
    {
        width:100%;
        padding:0;
        flex-direction: row;
        justify-content: space-around;
    }
    .placeOrderBox .cartTitle >:nth-child(1), .productTotal {
        font-size: 15px;
    }
    .product-page
    {
        align-items: center;
        flex-direction: column;
    }
    .product-page-r
    {
        width:100%;
    }
    .product-page-r button
    {
        width:100%;
    }
}
@media(max-width:769px)
{

    .navbar
    {
        width:100%;
        background: var(--grey-color);
        top:0;
        left:0;
        padding:0 20px;
        position:fixed;
        z-index: 1;
        backdrop-filter: blur(10px);
        background: rgba(0, 0, 0, 0.6);
        border-bottom: thin solid #85d47f3f;
    }
    .content,.product-page,.placeOrderBox,.content-box
    {
        margin-top:50px;
    }
    .wrapper, .footer-wrapper
    {
        width:90%;
    }

    .container-add-to-cart div span
    {
        display: none;
    }
    .product-info{
        justify-content: space-around;
    }
    .product-info-c
    {
        max-width:200px;
    }
}
@media (max-width:800px)
{
    .store
    {
        row-gap:10px;
    }
    .containerbox
    {
        width:49%;
        padding:10px;
    }
    .container-img img
    {
        width:100%;
    }
    .container-img
    {
        background: none;
    }
    .container-price s
    {
        display: none;
    }
    .container-title
    {
        font-size:15px;
    }
    .container-img .discount
    {
        display: none;
    }
}
@media (max-width:620px)
{
    .footer-columns
    {
        flex-direction: column;
        align-items: center;
    }
    .footer-contact
    {
        align-items: center;
    }
    #brand-footer, #brandlogo
    {
        width:180px;
        height:46px;
    }
    .socials-box
    {
        justify-content: center;
    }
    .footer-l-box{
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media (max-width:575px)
{
    .finalPriceBox
    {
        align-items: center;
    }
    .cartTitle span{
        font-size:20px;
    }
}
@media (max-width:425px)
{
    .anpc-box
    {
        flex-direction: column;
    }
    .product-price
    {
        justify-content: center;
        flex-wrap: wrap;
    }
}